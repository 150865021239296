import { Link } from "react-router-dom";

const NotFound = () => {
    return ( 
        <div className="container">
            <div className="not-found px-4 py-5 my-5 shadow">
                <h2>Page not Found</h2>
                <p>This page can not be found.</p>
                <Link to="/"></Link>
            </div>
        </div>
    );
}
 
export default NotFound;