import { Link } from 'react-router-dom';


const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-md navbar-dark bg-dark">
            <div className="container-fluid">
                <a className="navbar-brand text-danger" href="#">The Dojo Blog</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample01" aria-controls="navbarsExample01" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarsExample01">
                    <ul className="navbar-nav me-auto mb-2 mb-md-0">
                        <li className="nav-item">
                            {/* <a className="nav-link active" href="/">Home</a> */}
                            <Link className="nav-link" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            {/* <a className="nav-link active btn btn-danger" href="/create/">New Blog</a> */}
                            <Link className="nav-link" to="/create/">New Blog</Link>
                        </li>
                        {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Blog</a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="/create/">New</a></li>
                            </ul>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    );
}
 

       

export default Navbar;