import { useState } from "react";
import { useHistory } from 'react-router-dom';


const Create = () => {
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [author, setAuthor] = useState('Mario');
    const [isPending, setIsPending] = useState(false);
    const history = useHistory();

    const handleSubmit = (e) => {
        e.preventDefault();
        const blog = { title, body, author };

        setIsPending(true);

        fetch('http://localhost:8000/blogs', {
            method: 'POST',
            headers: { "Content-Type": "application/json"},
            body: JSON.stringify(blog)
        }).then(() => {
            console.log('New Blog added');
            setIsPending(false);
            // history.go(-1);
            history.push('/');
        })
    }

    return ( 
        <div className="create">
            <div className="container">
                <div className="col-12 px-4 py-5 my-5 border-top shadow">
                    <h3>Add a new blog item</h3>
                    <form onSubmit={handleSubmit}
                    >
                        <label className="form-label">Blog title:</label>
                        <input
                            className="form-control"
                            type="text"
                            required
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <label className="form-label">Blog body:</label>
                        <textarea
                            className="form-control"
                            required
                            value={body}
                            onChange={(e) => setBody(e.target.value)}
                        >
                        </textarea>
                        <label className="form-label">Blog author:</label>
                        <select className="form-select mb-4"
                            value={author}
                            onChange={(e) => setAuthor(e.target.value)}
                        >
                            <option value="Mario">Mario</option>
                            <option value="Yoshi">Yoshi</option>
                            <option value="Luigi">Luigi</option>
                        </select>
                        { !isPending && <button className="btn btn-primary">Add Blog</button> }
                        { isPending && <button className="btn btn-secondary" disabled>Adding blog...</button> }
                    </form>
                </div>
            </div>
        </div>
     );
}
 
export default Create;