import { Link } from "react-router-dom";

const BlogList = ({blogs, title}) => {


    return ( 
        <div className="blog-list">
            <h1>{title}</h1>
            {blogs.map((blog) => (
                <div className="blog-preview px-4 py-5 my-5 border-top shadow" key={blog.id}>
                    <h2 className="display-10 fw-bold">{blog.title}</h2>    
                    <div className="col-lg-6">
                        <p className="lead mb-4">Quickly design and customize responsive mobile-first sites with Bootstrap, the world’s most popular front-end open source toolkit, featuring Sass variables and mixins, responsive grid system, extensive prebuilt components, and powerful JavaScript plugins.</p>
                        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                        <Link className="btn btn-outline-dark" to={`/blogs/${blog.id}/`}>Details</Link>
                    </div>
                    </div>
                    <p className="float-end">Author: {blog.author}</p>
                </div>
            ))}
        </div>
     );
}
 
export default BlogList;