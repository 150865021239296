import BlogList from "./BlogList";
import useFetch from "./useFetch";

const Home = () => {
    const { data: blogs, isPending, error } = useFetch('http://localhost:8000/blogs');

    return ( 
        <div className="home">
            <div className="container">
                { error && <div className="alert alert-danger">{error}</div>}
                { isPending && <div className="loading">Loading...</div> }
                { blogs && <BlogList blogs={blogs} title="All Blogs!" /> }
            </div>
        </div>
     );
}
 
export default Home;