import { Link, useHistory, useParams } from "react-router-dom";
import useFetch from './useFetch';

const BlogDetails = () => {
    const {blog_id} = useParams();
    const { data: blog, error, isPending } = useFetch('http://localhost:8000/blogs/' + blog_id + '/');
    const history = useHistory();


    const handleClick = () => {
        fetch('http://localhost:8000/blogs/' + blog.id, {
            method: 'DELETE'
        }).then(() => {
            history.push('/');
        })
    }

    return ( 
        <div className="container">
            <div className="blog-details px-4 py-5 my-5 shadow">
                { isPending && <div className="loading">Loading</div> }
                { error && <div className="alert alert-error">{error}</div> }
                { blog && (
                    <article>
                        <h2 className="display-10 fw-bold">{ blog.title }</h2>
                        <div className="col-lg-6">
                            <p className="lead mb-4">{ blog.body }</p>
                            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                                <Link to="/" className="btn btn-outline-dark">Return</Link>
                            </div>
                        </div>
                        <p className="float-end">Author: {blog.author}</p>
                        <button className="btn btn-danger btn-sm" onClick={handleClick}>delete</button>
                    </article>
                )}
            </div>
        </div>
     );
}
 
export default BlogDetails;